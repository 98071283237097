import axios from 'axios';
import auth from './auth';

class CommentedItems {

    constructor(options) {
        const defaults = {
            containerSelector: '#commented-items',
            templateSelector: '#commented-item-template',
            noCommentsMessage: 'Δεν έχετε σχολιάσει άρθρα',
            gridClass: 'grid-cards',
        };
        this.params = Object.assign({}, defaults, options || {});
        this.container = document.querySelector(this.params.containerSelector);
        this.template = document.querySelector(this.params.templateSelector);
        this.items = [];
        this.start();
    }

    start() {

        window.addEventListener('dpgAuthReady', async () => {
            if (!auth.isLoggedIn) {
                window.location = window.location.protocol + '//' + window.location.host;
            }

            await this.fetchItems();
            this.renderItems();
        });

        window.addEventListener('dpgAuthLogout', () => {
            window.location = window.location.protocol + '//' + window.location.host;
        });


    }

    async fetchItems() {

        const token = window.localStorage.getItem('memberToken');

        try {
            const response = await axios.get(`https://${window.location.host}/component/news/?task=comments.commented&format=json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            });
            this.items = response.data.data;
        } catch (error) {
            console.log(error);
        }
    }

    renderItems() {
        if (!this.template) {
            return;
        }

        this.container.innerHTML = '';
        this.container.classList.remove(this.params.gridClass);

        if (this.items.length === 0) {
            this.container.innerHTML = '<p>' + this.params.noCommentsMessage + '</p>';
            return;
        }

        this.container.classList.add(this.params.gridClass);

        for (let index = 0; index < this.items.length; index++) {
            const item = this.items[index];
            const clone = this.template.content.cloneNode(true);
            clone.querySelector('.item-category').textContent = item.category;
            clone.querySelector('.item-image').src = item.img_thumb;
            clone.querySelector('.item-link').href = item.link;
            clone.querySelector('.item-link').textContent = item.title;
            this.container.appendChild(clone);
        }
    }
}

export {
    CommentedItems as
        default
};
